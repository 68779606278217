<template>
    <div>
        <div class="serviceCate" v-if="quoteData">
            <div class="cateHead">报价信息</div>
            <div class="serviceMoney serviceMoney2">
                <div class="item" v-if="quoteData.speakerAmount !== undefined">
                    <div class="left">培训费</div>
                    <div class="right">
                        <iFixedNum :num="quoteData.speakerAmount" />
                    </div>
                </div>
                <div class="item">
                    <div class="left">总金额</div>
                    <div class="right">
                        <iFixedNum :num="quoteData.costAmount" />
                    </div>
                </div>
                <!-- <div class="item">
          <div class="left">税额</div>
          <div class="right">
            <iFixedNum :num="quoteData.taxAmount" />
          </div>
        </div>
        <div class="item">
          <div class="left">成本金额</div>
          <div class="right">
            <iFixedNum :num="quoteData.unTaxAmount" />
          </div>
        </div>
        <div class="item">
          <div class="left">服务费</div>
          <div class="right">
            <iFixedNum :num="quoteData.serviceAmount" />
          </div>
        </div> -->
                <!--               <li>-->
                <!--                   毛利-->
                <!--                   <FixedNum :num="quoteData.gross"/>-->
                <!--               </li>-->
                <!--               <li>-->
                <!--                   毛利率-->
                <!--                   <FixedNum :num="quoteData.grossTax"/>-->
                <!--               </li>-->
            </div>
        </div>
    </div>
</template>

<script>
import { iFixedNum } from "@/components/iToolCase";

export default {
    name: "QuoteInfo",
    components: {
        iFixedNum,
    },
    data() {
        return {
            quoteData: undefined,
        };
    },
    props: {
        miceInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    created() {
        this.$root.$eventHub.$on("webAppBridgeListener", () => {
            this.getEventQuotation();
        });

        this.getEventQuotation();
    },
    destroyed() {
        this.$root.$eventHub.$off("webAppBridgeListener");
    },
    methods: {
        // 获取报价信息
        async getEventQuotation() {
            let params = {
                MiceId: this.miceInfo.MiceId,
            };
            let res = await this.$service.getEventQuotation(params);
            this.$smartStorage.set("quoteData", 0);
            if (res.success && res.content) {
                this.quoteData = res.content;
                this.$smartStorage.set("quoteData", this.quoteData);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.serviceCate {
    padding: 0.15rem 0.18rem 0 0.18rem;
    font-size: 0.13rem;
    .cateHead {
        color: #13161a;
        font-size: 0.16rem;
        font-weight: bold;
        text-align: left;
    }
    .serviceMoney {
        .item {
            display: flex;
            justify-content: space-between;
            margin-top: 0.1rem;
            .left {
                font-size: 0.15rem;
                color: #13161b;
            }
            .right span {
                font-size: 0.16rem;
                font-weight: bold;
                color: #13161b;
            }
        }
    }
}
</style>
