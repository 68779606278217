var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.settleData
      ? _c("div", { staticClass: "serviceCate" }, [
          _c("div", { staticClass: "cateHead" }, [_vm._v("结算信息")]),
          _c("div", { staticClass: "serviceMoney serviceMoney2" }, [
            _vm.settleData.speakerAmount !== undefined && !_vm.isDSM
              ? _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("培训费")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("iFixedNum", {
                        attrs: { num: _vm.settleData.speakerAmount },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [_vm._v("总金额")]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("iFixedNum", {
                    attrs: { num: _vm.settleData.costAmount },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }