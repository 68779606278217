<template>
    <div id="eventDetailContainer" class="event-detail-container" v-if="setup" v-on:scroll.passive="onScroll">
        <!-- 会议信息 -->
        <MiceInfoCard :otherBtnList="otherBtnList" :miceInfo="miceInfo" />
        <template v-if="miceInfo">
            <template v-if="$route.query.type == 'callCar'">
                <ServiceCategory :miceInfo="miceInfo"></ServiceCategory>
            </template>
            <template v-else>
                <template v-for="(module, index) in moduleList">
                    <component :key="index" :is="module.component" :miceInfo="miceInfo" :getDetail="getDetail"></component>
                </template>
            </template>
            <!--按钮-->
            <template v-if="$route.query.type != 'callCar'">
                <div class="btn-box" v-if="flowBtnList.length > 0">
                    <div class="btn-item" v-for="(item, index) of flowBtnList" :key="index">
                        <i-button block round :class="item.extData.class || ''" :type="item.extData.type"
                                  size="normal" @click.stop="handleProcessAct(item)">
                            {{ item.actName }}
                        </i-button>
                    </div>
                </div>
            </template>
            <!--退回原因-->
            <iDialogModule :isShow="showSendBack" :title="sendBackTitle" @beforeClose="beforeClose" :show-cancel-button="true">
                <i-field v-model="actDescr" rows="3" autosize :error="isActDescrEmpty" :border="false" type="textarea" maxlength="500" :placeholder="'请输入' + sendBackTitle" show-word-limit />
            </iDialogModule>
        </template>
    </div>
</template>

<script>
import MiceInfoCard from "./MiceInfoCard"; //会议信息
import ServiceCategory from "./ServiceCategory"; //服务
import QuoteInfo from "./QuoteInfo"; //报价
import SettleInfo from "./SettleInfo"; //结算
import FunctionalModule from "./FunctionalModule";
// import CollectionInfo from '../CollectionInfo'//收款
import BudgetCard from "./BudgetCard";
import handleProcessActions from "@/utils/handleProcessActions.js";
import EventProgress from "./EventProgress";
import todo from "./todo";
import generic from "../../utils/generic";
import {getCookie} from "tiny-cookie";

export default {
    name: "ConfirmPrice",
    mixins: [handleProcessActions],
    components: {
        MiceInfoCard,
        ServiceCategory,
        QuoteInfo,
        SettleInfo,
        FunctionalModule,
        BudgetCard,
        EventProgress,
        todo
    },
    data() {
        return {
            miceInfo: undefined,
            otherBtnList: [],
            flowBtnList: [],
            taskId: "",
            orderId: "",
            miceId: "",
            tenantId: this.$cookie.get("tenant_id") || "",
            showSendBack: false,
            sendBackTitle: "",
            actDescr: "",
            currentAct: {},
            isActDescrEmpty: false,
            setup: false,
            moduleList: [],
        };
    },
    watch: {
        actDescr(val) {
            if (val.trim() !== "") {
                this.isActDescrEmpty = false;
            }
        },
    },
    created() {
        this.orderId = this.$route.query.orderId;
        this.miceId = this.$route.query.miceId;

        this.$root.$eventHub.$on("webAppBridgeListener", () => {
            this.QueryElements();
            this.getDetail();
        });
        this.QueryElements();
        this.getDetail();
    },
    destroyed() {
        this.$root.$eventHub.$off("webAppBridgeListener");
    },
    methods: {
        // 获取详情页模块
        async QueryElements() {
            let params = {
                db: "smartx-tpm",
                collection: "cfg-ievent-module",
                filter: {
                    module: "eventDetail",
                },
                projection: {
                    _id: 0,
                },
                sort: {
                    dspOrder: 1,
                },
                ContextDataKeys: {
                    tenantId: this.tenantId,
                    MiceId: this.miceId,
                    Version: 3
                },
            };
            let res = await this.$service.QueryElements(params);
            if (res && res.success && res.content) {
                this.moduleList = [];
                res.content.map((item, index) => {
                    if (item.Result) {
                        this.moduleList.push(item.Result);
                    }
                });
              console.log(this.moduleList);
            }
        },
        onScroll() {},
        //获取会议详情
        getDetail() {
            let params = {
                projection: { _id: 0 },
                sort: {},
                filter: {
                    MiceId: this.miceId,
                },
            };
            this.setup = false;
            this.$service.GetDetail(params).then((res) => {
                if (res.success && res.content) {
                    console.log("====会议详情", res.content);
                    if (res.content.ExtData) {
                        res.content.ExtData = JSON.parse(res.content.ExtData);
                    }
                    let ExtData=res.content.ExtData;
                    this.miceInfo = res.content || {};
                    this.orderId = res.content.OrderId;

                    //进入会议详情页判断是否在会议期间存储
                    const curMoment=this.moment().format("YYYY-MM-DD");
                    const startMoment = this.moment(ExtData?.DtStart?.split(' ')[0]);
                    const endMoment = this.moment(ExtData?.DtEnd?.split(' ')[0]);
                    this.isMicePeriod = this.moment(curMoment).isBetween(startMoment, endMoment,null,'[]') || this.moment(curMoment).isSame(endMoment, 'day');
                    res.content.ExtData = {
                            ...res.content.ExtData,
                            isMicePeriod: this.isMicePeriod
                    };
                    this.$smartStorage.set("miceInfo", res.content);

                    this.taskId = res.content.TaskId;
                    if (this.taskId) {
                        this.queryTaskActions();
                    } else {
                        this.getLatestTaskIdOfUndone();
                    }
                } else {
                    this.$itoast.fail("订单错误,请联系管理员！");
                }
                this.setup = true;
            });
        },
        // 获取最新的taskId
        async getLatestTaskIdOfUndone() {
            const params = {
                processId: this.miceId,
            };
            const res = await this.$service.GetLatestTaskIdOfUndone(params);
            if (res && res.success) {
                this.taskId = res.content;
                this.queryTaskActions();
            }
        },
        //获取操作按钮
        queryTaskActions() {
            let params = {
                displayType: "detail",
                processId: this.miceId,
                taskId: this.taskId,
                userId: this.$smartStorage.get("userId"),
            };
            // 按照宾成龙的说法，住友是不会有操作按钮的，要求不请求这个接口
            if(!(['dsm', 'msd'].includes(getCookie('tenant'))) ) {
                this.$service.QueryTaskActions(params).then((res) => {
                    if (res.success) {
                        // console.log("====会议操作按钮", res.content);
                        this.flowBtnList = [];
                        this.otherBtnList = [];
                        res.content.map((item) => {
                            item.extData = JSON.parse(item.extData || "{}");
                            if (!item.extData.displayMode || item.extData.displayMode.includes("app")) {
                                this.flowBtnList.push(item);
                            } else if (item.extData.displayMode.includes("app-other")) {
                                this.otherBtnList.push(item);
                            }
                        });
                        console.log('下面的按钮',this.flowBtnList)
                        console.log('左上角的按钮',this.otherBtnList);
                    }
                });
            }
        },
        // 执行流程操作
        handleProcessAct(btn) {
            this.setInit(btn);
        },
        //打开消息弹框
        flowActionClick(btn) {
            this.currentAct = btn;
            let extData = btn.extData;
            let alertMsg=`是否确认 ${btn.actName} ？`;
            
            if (extData.isNeedInputConfirm) {
                this.showSendBack = true;
                this.sendBackTitle = extData.inputTitle;
                return false;
            }
            this.$idialog
                .confirm({
                    title: "提示",
                    message:alertMsg
                })
                .then(() => {
                    // on confirm
                    this.performTask({ btn: btn });
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 审批退回
        beforeClose(action, done) {
            if (action === "cancel") {
                done();
                this.showSendBack = false;
            } else if (action === "confirm") {
                let btnExtData = this.currentAct.extData || {}
                if (!btnExtData.Optional && this.actDescr.trim() == "") {
                    this.isActDescrEmpty = true;
                    done(false);
                    return false;
                }
                this.performTask({ btn: this.currentAct, isDbConfirm: false, actDescr: this.actDescr.trim() });
                done();
                this.showSendBack = false;
            }
        },
        //流程按钮操作
        performTask({ btn = undefined, isDbConfirm = false, actDescr = "" }) {
            let params = {
                taskId: this.taskId,
                actionId: btn.actionId,
                actDescr: actDescr,
            };
            if (isDbConfirm) {
                params.isDbConfirm = true;
            }
            this.$service.PerformTask(params).then((res) => {
                    if (res.success) {
                    if (res.code) {
                        this.$idialog
                            .confirm({
                                title: "提示",
                                message: res.msg,
                                messageAlign: 'left'
                            })
                            .then(() => {
                                // on confirm
                                this.performTask({ btn: btn, isDbConfirm: true });
                            })
                            .catch(() => {
                                // on cancel
                            });
                        return;
                    }
                    if (btn && btn.extData.api) {
                        this.queryBtnApi(btn);
                        return false;
                    }
                    this.$itoast.success("操作成功");
                    this.QueryElements();
                    this.getDetail();
                    this.getLatestTaskIdOfUndone(this.miceId);
                }
            });
        },
        // 流程按钮api
        queryBtnApi(item) {
            let params = {
                ProcessId: this.miceInfo.MiceId,
                TaskId: this.miceInfo.TaskId,
                ActionId: item.actionId,
                ActDescr: this.actDescr.trim() || "",
            };
            this.$service.common(item.extData.api.url, params).then((res) => {
                if (res && res.success) {
                    this.$itoast.success("操作成功");
                    this.QueryElements();
                    this.getDetail();
                    this.getLatestTaskIdOfUndone(this.miceId);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.event-detail-container {
    width: 100vw;
    height: 100%;
    padding-bottom: 1rem;
    overflow-y: auto;
    box-sizing: border-box;

    .btn-box {
        padding: 0 0.16rem;
        box-sizing: border-box;
        width: 100%;
        height: 0.7rem;
        display: flex;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        font-size: 0.16rem;
        font-weight: 500;
        background: #ffffff;

        .btn-item {
            width: 100%;
            margin-left: 0.05rem;
            /deep/ .van-button--normal {
                padding: 0 0.08rem !important;
            }
            .delete {
                color: #909398 !important;
            }
        }
        .btn-item:nth-child(1) {
            margin-left: unset;
        }
    }
    .textarea-box {
        width: 100%;
        padding: 0 0.27rem;
        box-sizing: border-box;
        textarea {
            width: 100%;
            resize: unset;
        }
    }
}

</style>
