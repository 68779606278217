<template>
    <div>
        <div class="serviceCate" v-if="settleData">
            <div class="cateHead">结算信息</div>
            <div class="serviceMoney serviceMoney2">
                <div class="item" v-if="settleData.speakerAmount !== undefined && !isDSM">
                    <div class="left">培训费</div>
                    <div class="right">
                        <iFixedNum :num="settleData.speakerAmount" />
                    </div>
                </div>
                <div class="item">
                    <div class="left">总金额</div>
                    <div class="right">
                        <iFixedNum :num="settleData.costAmount" />
                    </div>
                </div>
                <!-- <div class="item">
          <div class="left">税额</div>
          <div class="right">
            <iFixedNum :num="settleData.taxAmount" />
          </div>
        </div>
        <div class="item">
          <div class="left">成本金额</div>
          <div class="right">
            <iFixedNum :num="settleData.unTaxAmount" />
          </div>
        </div>
        <div class="item">
          <div class="left">服务费</div>
          <div class="right">
            <iFixedNum :num="settleData.serviceAmount" />
          </div>
        </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { iFixedNum } from "@/components/iToolCase";
import {getCookie} from "tiny-cookie";

export default {
    name: "SettleInfo",
    components: {
        iFixedNum,
    },
    computed: {
        isDSM(){
            return getCookie('tenant') === 'dsm';
        }
    },
    data() {
        return {
            settleData: undefined,
        };
    },
    props: {
        miceInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    created() {
        this.$root.$eventHub.$on("webAppBridgeListener", () => {
            this.getEventSettlement();
        });

        this.getEventSettlement();
    },
    destroyed() {
        this.$root.$eventHub.$off("webAppBridgeListener");
    },
    methods: {
        // 获取结算信息
        async getEventSettlement() {
            let params = {
                MiceId: this.miceInfo.MiceId,
            };
            let res = await this.$service.getEventSettlement(params);
            this.$smartStorage.set("settleData", 0);
            if (res.success && res.content) {
                this.settleData = res.content;
                this.$smartStorage.set("settleData", this.settleData);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.serviceCate {
    padding: 0.15rem 0.18rem 0 0.18rem;
    font-size: 0.13rem;
    .cateHead {
        color: #13161a;
        font-size: 0.16rem;
        font-weight: bold;
        text-align: left;
    }
    .serviceMoney {
        .item {
            display: flex;
            justify-content: space-between;
            margin-top: 0.1rem;
            .left {
                font-size: 0.15rem;
                color: #13161b;
            }
            .right span {
                font-size: 0.16rem;
                font-weight: bold;
                color: #13161b;
            }
        }
    }
}
</style>
