<template>
    <div>
        <template v-if="miceInfo">
            <div class="title-box" ref="titleBox">
                <i-sticky>
                    <i-header :scrollContainer="'eventDetailContainer'" title="会议详情" theme="light" :showBack="true" @back-event="backPage">
                        <template v-slot:right>
                            <template v-if="$route.query.type != 'callCar'">
                                <i-popover get-container=".title-box" v-if="otherBtnList.length" v-model="showPopover" trigger="click" placement="bottom-end">
                                    <ul class="confirmMenu">
                                        <li v-for="(item, index) of otherBtnList" :key="index" @click="changeMenu(item)">
                                            <i-icon color="#13161B" :name="item.extData.icon" />
                                            {{ item.actName }}
                                        </li>
                                    </ul>
                                    <template #reference>
                                        <i-icon color="#fff" name="icon-more" />
                                    </template>
                                </i-popover>
                            </template>
                        </template>
                    </i-header>
                </i-sticky>
                <component v-if="mianLabelsTemplate" :is="$evalTemplate('Vnode', this.mianLabelsTemplate)" @handleMoreTips="showMoreTips = !showMoreTips"></component>
                <div v-else class="confirmInfo">
                    <p class="confirmInfoHead">{{ miceInfo.Name }}</p>
                    <p class="confirmInfoCode" v-if="isCSPC">{{ miceInfo.PONum }}</p>
                    <p class="confirmInfoCode" v-else>{{ miceInfo.EventNum }}</p>
                    <div class="confirmInfoMsg">
                        <div v-if="miceInfo.DtStart">
                            <i-icon :size="15" color="#fff" name="icon-rili" />
                            {{ miceInfo.DtStart.split("T")[0] }} ~ {{ miceInfo.DtEnd.split("T")[0] }}
                        </div>
                        <div>
                            <i-icon :size="15" color="#fff" name="icon-shijian" />
                            {{ getTime || "暂无" }}
                        </div>
                        <div>
                            <i-icon :size="15" color="#fff" name="icon-weizhi" />
                            {{miceInfo.Province && miceInfo.City ? `${miceInfo.Province} - ${miceInfo.City}` : `${miceInfo.City}`}}
                            <template v-if="miceInfo.ExtData && miceInfo.ExtData['region']"> - {{ miceInfo.ExtData["region"] }}</template>
                        </div>
                        <div v-if="miceInfo.CustomerName">
                            <i-icon :size="15" color="#fff" name="icon-banhuiren" />
                            {{ miceInfo.CustomerName }}
                        </div>
                    </div>
                    <div class="showMoreTips" @click="showMoreTips = !showMoreTips">
                        {{ showMoreTips ? '收起查看详细信息' : '展开查看详细信息' }}
                        <i-icon :size="10" color="#fff" :name="showMoreTips ? 'icon-jiantoushang' : 'icon-jiantouxia'" />
                    </div>
                </div>
                <i-skeleton v-if="!miceInfo.Name" :rowStyle="rowStyle" />
            </div>
            <div class="baseEventInfo" v-if="miceInfo.Name && showMoreTips" :style="{ height: titleBoxOffsetTop }">
                <div class="title">会议基本信息</div>
                <div class="cnt-box">
                    <template v-if="miceInfo.DirectoryId">
                        <template v-for="(item, index) in DirectoryIdMatchInfo">
                            <div class="cnt-item" :style="item.style || ''" :key="index" v-if="!$evalTemplate(item.visibleRuleExpr)">
                                <div class="left">{{ item.label }}</div>
                                <iNoCnt class="right" :txt="$evalTemplate(item.render)"></iNoCnt>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="choseBox" @click="showMoreTips = false">
                    <i-icon :size="20" color="#909399" name="icon-jiantoushang" />
                </div>
            </div>

            <!--            <div class="confirmEventInfo" v-if="miceInfo.Name">-->
            <!--                <ul class="confirmEventMsg">-->
            <!--                    <template v-if="miceInfo.DirectoryId">-->
            <!--                        <template v-for="(item, index) in DirectoryIdMatchInfo">-->
            <!--                            <li :style="item.style || ''" :key="index" v-if="!$evalTemplate(item.visibleRuleExpr)">-->
            <!--                                <div>{{ item.label }}</div>-->
            <!--                                <iNoCnt :txt="$evalTemplate(item.render)"></iNoCnt>-->
            <!--                            </li>-->
            <!--                        </template>-->
            <!--                    </template>-->
            <!--                </ul>-->
            <!--            </div>-->

            <ProcessModule padding="" :visible="processVisible" :processList="processList" title="会议进程" headKey="nodeName" onProcess="processOn" @close="processVisible = false" :processKey="processKey" />
        </template>
    </div>
</template>

<script>
/* eslint-disable */
import { iNoCnt } from "@/components/iToolCase";
import ProcessModule from "./ProcessModule";
import generic from "../../utils/generic";
import {getCookie} from "tiny-cookie";
import {SmartStorage} from "smart-core-util";

export default {
    name: "MiceInfo",
    components: {
        iNoCnt,
        // iFixedNum,
        ProcessModule,
    },
    props: {
        miceInfo: null,
        otherBtnList: [],
    },
    data() {
        return {
            showPopover: false,
            rowStyle: { "margin-bottom": 0 },
            processList: [],
            processKey: '',
            processVisible: false,
            infoTemplate: [],
            mianLabelsTemplate: null,

            showMoreTips: false,
            titleBoxOffsetTop: ''
        };
    },
    computed: {
        isCSPC() {
            return this.miceInfo.DirectoryId == "7faf5393-8a70-11ec-89d2-6a0616dfdee4" ? true : false;
        },
        isDsmTravel(){ // 住友的差旅
            return getCookie('tenant') === 'dsm' && SmartStorage.get('selectedEventType') === 'travel';
        },
        getTime() {
            return this.miceInfo.ExtData["DtStart"] && this.miceInfo.ExtData["DtEnd"] ? this.miceInfo.ExtData["DtStart"].split(" ")[1] + " - " + this.miceInfo.ExtData["DtEnd"].split(" ")[1] : "";
        },
        isTMIS(){
            return getCookie('tenant') === 'pfizer' && this.$smartStorage.get('selectedEventType') === 'TMIS' // 辉瑞老yes
        },
        DirectoryIdMatchInfo() {
          if (this.miceInfo && this.infoTemplate.length) {
            let context = this;
                try {
                    return this.infoTemplate.find((x) => eval(x.expr))?.labels;
                } catch (error) {
                    console.log(error);
                }
            } else {
                return [];
            }
        },
        isIOSonWxWork(){// ios且在企业微信
            return /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) && /wxwork/i.test(navigator.userAgent.toLowerCase());
        },
    },
    watch: {
        showMoreTips(newVal) {
            if(newVal) {
                document.getElementById('eventDetailContainer').style = 'overflow-y: hidden';
            } else {
                document.getElementById('eventDetailContainer').style = 'overflow-y: auto';
            }
        }
    },
    async created() {
        // 获取显示模板
        let params = {
            collection: "cfg-ievent-infoCard-template",
            filter: {
                platform: "app",
                tenantCode: this.$cookie.get("tenant") || this.$smartStorage.get("tenant"),
                tenantId: this.$cookie.get("tenant_id") || "",
            },
        };
        if(getCookie('tenant') === 'pfizer'){ // 辉瑞的查询新增一个type查询条件区分拼团、mapp等
            params.filter.type = this.$smartStorage.get('selectedEventType')
        }

        let res = await this.$service.GetSettings(params);
        if (res && res.success && res.content) {
            console.log('moban',res);
            this.infoTemplate = res.content.infoLabels;
            this.mianLabelsTemplate = res.content.mianLabels;
            // 处理进详情就弹提示的傻逼需求...
            if(res.content.configList) {
                let context = this.miceInfo;
                let _this = this;
                // 验证表单数据
                let checkExpress = res.content.configList.filter((x) => {
                    try {
                        return eval(x.expr) && x.type === 'init';
                    } catch (error) {
                        console.log(error);
                    }
                });
                if (checkExpress.length) {
                    checkExpress.map((express) => {
                        express.rules.map((rule) => {
                            try {
                                if (eval(rule.expr)) {
                                    setTimeout(() => {
                                        this.$itoast(rule.errorMsg);
                                    },800)
                                }
                            } catch (error) {
                                console.log(error);
                            }
                        });
                    });
                }
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.handleScroll()
        },500)
        document.querySelector('#eventDetailContainer').addEventListener('scroll',this.handleScroll)
    },
    methods: {
        handleScroll() {
            if(this.miceInfo) {
                let top = document.querySelector('#eventDetailContainer')?.pageYOffset || document.querySelector('#eventDetailContainer')?.scrollTop || 0
                let titleBoxHeight = this.$refs.titleBox.offsetHeight
                let distance = Math.max(0, (top - titleBoxHeight) * -1)
                this.titleBoxOffsetTop = `calc(100vh - ${this.isIOSonWxWork ? distance + 34 : distance }px)`
            }
        },
        changeMenu(item) {
            this[item.actCode](item);
        },
        // 打开会议进程
        processing(){
            this.processOnPfizer();
        },
        // 辉瑞编辑会议
        editConference(){
            this.$dialog.confirm({
                message: '更改会议信息将导致已添加的需求被清空',
                title: '再次确认'
            }).then(() => {
                this.editMeeting()
            })
        },

        // 辉瑞取消会议、会议零结算
        BatchedPerformTask(item){
           const params = {"actionId":item.actionId,"taskId":this.miceInfo.TaskId, "actDescr": ""}
            this.$service.PerformTask(params).then(res => {
                if(res.success){
                    this.$toast.success(item.msg || '操作成功');
                    this.$router.go(-1)
                }
            })
        },
        // 辉瑞取消会议
        Cancel(item){
            this.handlePfizerAction(item);
        },
        // 会议零结算
        settleZero(item){
            this.handlePfizerAction(item);
        },
        handlePfizerAction(item){
            const eventMap = new Map([
                ["Cancel", "Pfizer.CancelEvent.Remind"], //辉瑞.取消会议提醒
                ["cancelAfterFace", "Pfizer.CancelEvent.Remind"], //辉瑞.取消会议提醒
                ["rebackSettle", "Pfizer.ReactEvent.Remind"], //辉瑞.撤回报销提醒
                ["rebackQuoteAfterFace", "Pfizer.UpdateEvent.Remind"], //辉瑞.修改会议提醒
                ["settleZero", "Pfizer.ZeroEvent.Remind"], //辉瑞.零结算会议提醒
            ]);
            let params = {
                proposalId: this.miceInfo.MiceId,
                KeyCode: eventMap.get(item.actCode),
            };
            this.$dialog.confirm({
                message: `是否确认${item.actName}?`,
                title: '提示'
            }).then(() => {
                // 二次提醒
                this.$service.PfizerQueryOperationWarns(params).then(res => {
                    if(res.success){
                        var tips = res.content;
                        if (tips && tips.length > 0) {
                            this.tipFactory(tips, tips.shift(), item);
                        } else {
                            this.BatchedPerformTask(item);
                        }
                    }
                })
            })
        },
        tipFactory(tips = [], tip = {}, item) {
            if (tip.type == "toast") {
                this.$toast(tip.txt);
                return false;
            }
            this.$dialog.confirm({
                title: tip.title || "再次确认",
                message: tip.txt,
            }).then(() => {
                this.BatchedPerformTask(
                    Object.assign(item, {
                        success: tip.success,
                    })
                );
            })
        },
        backPage() {
            if (this.$route.query.type == "callCar") {
                this.$router.back();
            } else {
                // 获取当前会议是审批还是创建会议
                let eventType = this.$smartStorage.get("selectedEventType");
                const fullPath = this.$smartStorage.get("fullPath");
                if(fullPath){
                    this.$router.push(fullPath);
                    this.$smartStorage.remove('fullPath');
                }else {
                    this.$router.push(
                        eventType
                            ? {
                                name: "eventList",
                                query: {
                                    eventType: this.$smartStorage.get("selectedEventType"),
                                },
                            }
                            : {
                                name: "eventList",
                            }
                    );
                }
            }
        },
        // 编辑会议
        editMeeting() {
            if(this.isTMIS){
                this.miceInfo.ExtData.eventCity = this.miceInfo?.ExtData?.city?.txt || '';
                this.miceInfo.ExtData.DtStart = this.miceInfo?.DtStart || '';
                this.miceInfo.ExtData.DtEnd = this.miceInfo?.DtEnd || '';
                this.miceInfo.ExtData.DtStartTime = this.miceInfo?.ExtData.dtStartTime || '';
                this.miceInfo.ExtData.DtEndTime = this.miceInfo?.ExtData.dtEndTime || '';
                this.miceInfo.meetingPlace = this.miceInfo?.PlaceAddress || '';
                this.miceInfo.typeDictVal = this.miceInfo?.TypeDictVal || '';
                this.miceInfo.eventCity = this.miceInfo.City + '/' + this.miceInfo.Province; // TODO 这一块，在提交的时候还要再拆开，成City: xxx， Province: ccc
            }
            this.$store.commit("setValue", {
                key: "miceInfo",
                value: this.miceInfo,
            });
            this.$router.push({ name: "createEvent", query: { type: "edit" } });
        },
        // 刷新会议
        async refreshMeetingInfo(item) {
            let _config = generic.replaceEvalObject(this.jsonCopy(item.extData || {}),this.$smartStorage.get('miceInfo'))
            const res = await this.$service.GenericPost(_config);
            if (res && res.success) {
                this.$itoast('操作完成！')
            }
        },
        // 查看辉瑞的进程
        processOnPfizer(){
            const params = {
                "filters": {
                        "processId": this.miceInfo.MiceId,
                        "businessId": this.miceInfo.MiceId,
                        "nodeTypes":"1,5",
                        "sort":"desc",
                        "flowCode":"TMIS-PFIZER"
                    }
            }
            this.$service.QueryAccessTaskLogsForPfizer(params).then(res => {
                if(res.success){
                    let arr = [];
                    this.processKey = require(`@/assets/mock/pfizerProcessKey.json`);
                    res.content.map((value) => {
                        value.newCreator = `${value.executorDspName || "未知"}(${value.executorAccount || "未知"})`;
                        value.dtCreated = value.dtCreated ? value.dtCreated.replace("T", " ") : "暂无";
                        value.dtDone = value.dtDone ? value.dtDone.replace("T", " ") : "暂无";
                        value.actDescr = value.actDescr || "暂无";
                        if (value.taskStatus == "待办") {
                            value.processOn = true;
                        } else {
                            value.processOn = false;
                        }
                        arr.push(value);
                    });
                    this.processVisible = true;
                    this.processList = res.content;
                }
            })
        },
        //查看进程
        process() {
            let params = {
                filters: {
                    processId: this.miceInfo.MiceId,
                    businessId: this.miceInfo.MiceId,
                    nodeTypes: "1,5",
                    sort: "desc",
                    category: "CrossEvent-GROUP",
                },
            };
            this.$service.QueryAccessTaskLogs(params).then((res) => {
                if (res.success) {

                    this.processKey = require(`@/assets/mock/processKey.json`);

                    let arr = [];
                    res.content.map((value) => {
                        value.newCreator = `${value.executorDspName || "未知"}(${value.executorAccount || "未知"})`;
                        value.dtCreated = value.dtCreated ? value.dtCreated.replace("T", " ") : "暂无";
                        value.dtDone = value.dtDone ? value.dtDone.replace("T", " ") : "暂无";
                        value.actDescr = value.actDescr || "暂无";
                        if (value.taskStatus == "已办") {
                            value.processOn = true;
                        } else {
                            value.processOn = false;
                        }
                        arr.push(value);
                    });
                    this.processVisible = true;
                    this.processList = arr;
                }
            });
        },
    },
    beforeDestroy() {
        document.querySelector('#eventDetailContainer') && document.querySelector('#eventDetailContainer').removeEventListener('scroll',this.handleScroll)
    }
};
</script>

<style lang="less" scoped>
.confirmMenu {
    li {
        overflow: hidden;
        height: 0.45rem;
        //width: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #f5f5f5;
        font-size: 0.13rem;
        position: relative;
        padding: 0 0.12rem;
        svg {
            margin-right: 0.16rem;
        }
    }

    li:last-child {
        border-bottom: 0;
    }
}

.title-box {
    background: var(--themeColor);
    //border-bottom-left-radius: 0.34rem;
    //border-bottom-right-radius: 0.34rem;
    /deep/.van-popup{
        background-color: unset;
        border-radius: 0.04rem;
        box-shadow: 0 2px 2px #f5f5f5;
        //overflow: hidden;
        .van-popover__content{
            border: 1px solid #f5f5f5;
            border-radius: 0.04rem;
        }

    }

    .confirmInfo {
        box-sizing: border-box;
        padding: 0.08rem 0.18rem 0.14rem 0.18rem;
        color: #fff;
        //border-bottom-left-radius: 0.34rem;
        //border-bottom-right-radius: 0.34rem;

        .confirmInfoHead {
            font-size: 0.22rem;
            text-align: left;
        }

        .confirmInfoCode {
            font-size: 0.14rem;
            text-align: left;
        }

        .confirmInfoMsg {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 0.1rem;
            color: #fff;
            font-size: 0.14rem;

            div {
                margin-top: 0.05rem;
                display: flex;
                align-items: center;

                svg {
                    padding-right: 0.08rem;
                }
            }

            // :nth-child(1n) {
            //   width: 60%;
            // }

            // :nth-child(2n) {
            //   width: 40%;
            // }

            // :nth-child(3) {
            //   margin-top: 0.11rem;
            // }

            // :nth-child(4) {
            //   margin-top: 0.11rem;
            // }
        }

        .showMoreTips {
            margin-top: 0.14rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.12rem;
            font-weight: 500;
            color: #fff;
            svg {
                margin-left: 0.05rem;
            }
        }
    }
}

.baseEventInfo {
    width: 100vw;
    height: calc(100vh - 2.125rem);
    background-color: #FFFFFF;
    position: fixed;
    //top: 1.9rem;
    bottom: 0;
    left: 0;
    padding: 0.2rem 0.18rem;
    box-sizing: border-box;
    z-index: 1;
    overflow-y: auto;
    transition: all ease-in-out 1s;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    .title {
        font-size: 0.16rem;
        font-weight: bold;
        color: #000000;
    }
    .cnt-box {
        margin-top: 0.2rem;
        min-height: 3rem;
        .cnt-item {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 0.1rem;
            .left {
                width: 35%;
                font-size: 0.15rem;
                font-weight: 500;
                color: #909398;
            }
            .right {
                width: 65%;
                text-align: right;
                font-size: 0.15rem;
                font-weight: 500;
                color: #13161B;
            }
        }
    }
    .choseBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.2rem;
    }
}

.confirmEventInfo {
    background: #f6f7fb;
    padding: 0.15rem 0.16rem 0.15rem 0.16rem;
    border-bottom-left-radius: 0.34rem;
    border-bottom-right-radius: 0.34rem;
    font-size: 0.12rem;

    .confirmEventMsg {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {
            width: 50%;
            color: #909399;
            text-align: left;
            margin-bottom: 0.1rem;
            display: flex;
            line-height: 0.15rem;
            align-items: center;
            font-size: 0.12rem;

            :nth-child(1) {
                min-width: 0.68rem;
                text-align: left;
            }

            :nth-child(2) {
                color: #13161a;
            }
        }

        & > span {
            margin-left: 0.1rem;
        }
    }
}
</style>
