var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.categoryList && _vm.categoryList.length
        ? _c("div", { staticClass: "category-box" }, [
            _c("div", { staticClass: "title" }, [_vm._v("服务品类")]),
            _vm.isCanAddCategory
              ? _c(
                  "div",
                  {
                    staticClass: "add-btn",
                    on: {
                      click: function ($event) {
                        _vm.isChooseCategory = !_vm.isChooseCategory
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("i-icon", {
                          staticClass: "icon",
                          attrs: {
                            color: "var(--themeColor)",
                            name: "icon-add-bold",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", [_vm._v("添加服务品类")]),
                    _c("div", { staticClass: "cover" }),
                  ]
                )
              : _vm._e(),
            _vm.isChooseCategory
              ? _c("div", { staticClass: "all-category" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.categoryItems[0], function (item, index) {
                      return _c("div", { key: index, staticClass: "item" }, [
                        _c(
                          "div",
                          { staticClass: "category-icon" },
                          [
                            _c("i-icon", {
                              staticClass: "icon",
                              attrs: { name: item.icon },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "category-name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ])
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.categoryItems[1], function (category, catIndex) {
                      return _c("div", { key: catIndex, staticClass: "item" }, [
                        _c(
                          "div",
                          { staticClass: "category-icon" },
                          [
                            _c("i-icon", {
                              staticClass: "icon",
                              attrs: { name: category.icon },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "category-name" }, [
                          _vm._v(_vm._s(category.name)),
                        ]),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "the-checked-box" },
              _vm._l(_vm.categoryList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "the-checked-item",
                    on: {
                      click: function ($event) {
                        return _vm.changeService(index, item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [
                        _c("i-icon", {
                          staticClass: "left",
                          attrs: {
                            color: "#000000",
                            name: item.icon,
                            size: "25",
                          },
                        }),
                        _c("div", { staticClass: "cnt-box" }, [
                          _c("div", { staticClass: "cnt-title" }, [
                            _c("div", { staticClass: "title-name" }, [
                              _vm._v(_vm._s(item.name) + " "),
                              item.orderCount
                                ? _c("span", [
                                    _vm._v("(" + _vm._s(item.orderCount) + ")"),
                                  ])
                                : _vm._e(),
                            ]),
                            item.orderFlag == 2
                              ? _c("div", { staticClass: "title-tag" }, [
                                  _vm._v("未完成"),
                                ])
                              : _vm._e(),
                          ]),
                          item.orderFlag > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "cnt-desc",
                                  style: {
                                    width:
                                      !item.quoteAmt && !item.settleAmt
                                        ? ""
                                        : "2.3rem",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm._v("预算"),
                                      _c("iFixedNum", {
                                        attrs: { num: item.quoteAmt },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "margin-left": "0.07rem" },
                                    },
                                    [
                                      _vm._v("结算"),
                                      _c("iFixedNum", {
                                        attrs: { num: item.settleAmt },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        item.orderFlag == 0 &&
                        _vm.miceInfo.Status > 0 &&
                        _vm.miceInfo.Status <= 50 &&
                        !(
                          ["OfflineCar", "Hotel", "NonPlatform"].includes(
                            item.code
                          ) && _vm.miceInfo.Status == 50
                        )
                          ? _c("div", { staticClass: "tag" }, [
                              _vm._v("去添加"),
                            ])
                          : _vm.isMSD && item.name === "城市车队"
                          ? _c(
                              "div",
                              {
                                staticClass: "process",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openProcess($event)
                                  },
                                },
                              },
                              [_vm._v("进程")]
                            )
                          : _c("i-icon", {
                              attrs: { name: "icon-jiantouyou", size: "14" },
                            }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _c("i-skeleton", { attrs: { skList: _vm.cateList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }